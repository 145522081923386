
import { useStore } from '@/store/store';
import { referenceToString } from '@/utilities/utilityFunctions';
import { computed } from '@vue/reactivity';
import { defineComponent, ref } from 'vue';
import Button from '@/components/form/Button.vue';

export default defineComponent({
  name: 'SkippedVerses',
  props: {},
  setup() {
    const store = useStore();
    const selected = ref<number[]>([]);
    const skipped = computed(() => {
      return store.getters['session/skippedVerses']
        .map((v) => ({
          ...v,
          display: referenceToString(v),
        }))
        .sort((s1, s2) => s1.index - s2.index)
        .map((v, i) => ({
          ...v,
          selected: selected.value.includes(i),
        }));
    });
    function unskip(index: number) {
      store.dispatch('session/unskipVerse', index);
      clearSelection();
    }
    function unskipAll() {
      if (selected.value.length) {
        selected.value
          .map((v) => skipped.value.at(v)?.index)
          .forEach((index) => store.dispatch('session/unskipVerse', index));
        clearSelection();
        return;
      }
      skipped.value.forEach(({ index }) => store.dispatch('session/unskipVerse', index));
      clearSelection();
    }
    function select(index: number, event?: MouseEvent) {
      if (event?.shiftKey) {
        return selectRange(index);
      }
      if (!selected.value.includes(index)) {
        selected.value.push(index);
      }
    }
    function deselect(index: number) {
      if (selected.value.includes(index)) {
        selected.value.splice(selected.value.indexOf(index), 1);
      }
    }
    function selectRange(index: number) {
      const lastSelected = selected.value.at(-1);
      if (lastSelected !== undefined) {
        const min = Math.min(lastSelected, index);
        const max = Math.max(lastSelected, index);

        const toSelect = skipped.value
          .map((s, i) => i)
          .filter((i) => i <= max && i >= min);
        toSelect.forEach((i) => select(i));
      } else {
        select(index);
      }
    }
    function clearSelection() {
      selected.value = [];
    }

    return {
      skipped,
      unskip,
      unskipAll,
      select,
      deselect,
      selected,
      clearSelection,
    };
  },
  components: { Button },
});
