
import { useStore } from '@/store/store';
import { computed, defineComponent, markRaw, nextTick, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { PATH } from '@/router/router';
import QuizCard from '@/components/atoms/QuizCard.vue';
import { referenceToString } from '@/utilities/utilityFunctions';
import CardStackDisplay from '@/components/molecules/CardStackDisplay.vue';
import IconButton from '@/components/molecules/IconButton.vue';
import Toggle from '@/components/form/Toggle.vue';
import SessionControls from '@/components/molecules/SessionControls.vue';
import SkippedVerses from '@/components/molecules/SkippedVerses.vue';
import SessionComplete from '@/components/molecules/SessionComplete.vue';
import SessionSettings from '@/components/molecules/SessionSettings.vue';

export default defineComponent({
  name: 'QuoteSession',
  components: {
    QuizCard,
    CardStackDisplay,
    IconButton,
    Toggle,
    SessionControls,
  },
  mounted() {
    // If there is no session started, redirect to home page to set up session
    const store = useStore();
    if (!store.getters['session/limitedVerses']?.length) {
      const router = useRouter();
      router.push(PATH.home);
    }
  },
  setup() {
    const store = useStore();

    const mode = ref<'quote' | 'review'>('quote');

    const swipeIndColor = ref('');

    const spinner = ref<boolean>(false);

    const state = ref<'play' | 'pause' | 'stop' | 'record' | ''>('');

    const unquoted = computed(() => {
      const storeVal = store.getters['session/unquotedVerses'];
      return mode.value === 'quote' ? storeVal.slice(1) : storeVal;
    });
    const unreviewed = computed(() => {
      const storeVal = store.getters['session/unreviewedVerses'];
      const returnVal = [...storeVal];
      if (mode.value !== 'review') {
        returnVal.reverse();
      }
      return returnVal.slice(mode.value === 'review' ? 1 : 0);
    });
    const complete = computed(() => {
      return store.getters['session/reviewedVerses'];
    });
    const activeVerseRef = computed(() => {
      return (
        mode.value === 'quote'
          ? store.getters['session/unquotedVerses']
          : [...store.getters['session/unreviewedVerses']]
      ).at(0);
    });
    const activeVerseString = computed(() => {
      return (activeVerseRef.value && referenceToString(activeVerseRef.value)) || '';
    });
    const activeVerseText = computed(() => {
      return (
        ((mode.value === 'review' || displayText.value) &&
          activeVerseRef.value &&
          store.getters['verse/verses'](activeVerseRef.value) &&
          store.getters['verse/verses'](activeVerseRef.value).text) ||
        ''
      );
    });
    const autoAdvance = computed({
      get: () => store.state.session.options.autoAdvance,
      set: (value) => store.commit('session/setAutoAdvance', value),
    });
    const skipped = computed(() => {
      return store.getters['session/skippedVerses'];
    });

    async function setMode(md: typeof mode.value) {
      if (md !== mode.value) {
        await stop();
      }
      if (md === 'quote' && unquoted.value.length) {
        mode.value = md;
      } else if (md === 'review' && unreviewed.value.length) {
        spinner.value = true;
        store.dispatch('verse/fetchVerses', unreviewed.value).then(() => {
          spinner.value = false;
        });
        mode.value = md;
      }
    }

    function testSwipe(direction: string) {
      if (direction === 'right') {
        swipeIndColor.value = '#00ff00';
      } else if (direction === 'left') {
        swipeIndColor.value = '#ff0000';
      }

      if (['right', 'left'].includes(direction)) {
        setTimeout(() => {
          swipeIndColor.value = '';
        }, 250);
      }
    }

    function openSkippedOverlay() {
      if (skipped.value.length) {
        store.dispatch('ui/openOverlay', {
          id: 'skipped-overlay',
          type: 'modal',
          component: markRaw(SkippedVerses),
          containerClasses: 'qa-skipped-verses-overlay',
          closeButton: true,
        });
      }
    }

    async function openCompleteOverlay() {
      await stop();
      store.dispatch('ui/openOverlay', {
        id: 'session-complete-overlay',
        type: 'modal',
        component: markRaw(SessionComplete),
        containerClasses: 'qa-session-complete-overlay',
        closeButton: true,
      });
    }

    async function openSettingsOverlay() {
      await stop();
      store.dispatch('ui/openOverlay', {
        id: 'session-settings-overlay',
        type: 'modal',
        component: markRaw(SessionSettings),
        containerClasses: 'qa-session-settings-overlay',
        closeButton: true,
      });
    }

    async function stop() {
      state.value = 'stop';
      await nextTick().then(() => {
        state.value = '';
      });
    }

    watch(unreviewed, () => {
      if (
        store.getters['session/unreviewedVerses'].length === 0 &&
        mode.value === 'review'
      ) {
        setMode('quote');
      }
    });

    const displayText = computed(() => store.state.session.displayText);

    const coachMode = computed(() => store.state.session.options.coachMode);

    watch(
      [displayText, coachMode, activeVerseString],
      () => {
        if (displayText.value && coachMode.value && !activeVerseText.value) {
          spinner.value = true;
          store
            .dispatch('verse/fetchVerses', store.getters['session/unquotedVerses'])
            .then(() => {
              spinner.value = false;
            });
        }
      },
      { immediate: true }
    );

    return {
      unquoted,
      unreviewed,
      complete,
      activeVerseRef,
      activeVerseString,
      activeVerseText,
      autoAdvance,
      skipped,
      testSwipe,
      swipeIndColor,
      mode,
      setMode,
      spinner,
      openSkippedOverlay,
      openCompleteOverlay,
      openSettingsOverlay,
      state,
      displayText,
      coachMode,
    };
  },
});
