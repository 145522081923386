
import { computed, defineComponent } from 'vue';
import Toggle from '@/components/form/Toggle.vue';
import { useStore } from '@/store/store';

export default defineComponent({
  name: 'SessionSettings',
  components: { Toggle: Toggle },
  setup() {
    const store = useStore();
    const coachMode = computed({
      get: () => store.state.session.options.coachMode,
      set: (value: boolean) => store.commit('session/setCoachMode', value),
    });
    return {
      coachMode,
    };
  },
});
