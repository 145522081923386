
import { computed } from '@vue/reactivity';
import { defineComponent, nextTick, onMounted, PropType, ref } from 'vue';

let tabRadioCount = 0;

export default defineComponent({
  name: 'qa-tab-radio',
  props: {
    options: {
      type: Array as PropType<
        Array<{ title?: string; value: string | boolean | object | number }>
      >,
      default: Array,
    },
    modelValue: {
      type: [String, Object, Boolean, Array, Number],
      default: '',
    },
    name: {
      type: String,
      default: () => `tabRadio-${++tabRadioCount}`,
    },
  },
  emits: {
    'update:modelValue': null,
  },
  setup(props, { emit }) {
    const refs = {
      labels: ref([]),
      radios: ref([]),
    };

    const value = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const selectStyle = computed(() => {
      let width = 0;
      let left = 3;

      if (!refs.labels.value || !refs.radios.value) {
        return {};
      }
      const labels: HTMLLabelElement[] = (
        Array.isArray(refs.labels.value) ? refs.labels.value : [refs.labels.value]
      ).filter((v) => v !== undefined);
      const radios: HTMLInputElement[] = (
        Array.isArray(refs.radios.value) ? refs.radios.value : [refs.radios.value]
      ).filter((v) => v !== undefined);
      for (let i = 0; i < labels.length; i++) {
        if (radios[i]?.value === value.value) {
          width = labels[i]?.clientWidth;
          break;
        }
        left += labels[i]?.clientWidth + 10;
      }

      return {
        width: `${width}px`,
        left: `${left}px`,
      };
    });

    const afterMount = ref(false);

    onMounted(() => {
      nextTick().then(() => {
        afterMount.value = true;
      });
    });

    function selectNext() {
      const selectedIndex = props.options.findIndex((o) => o.value === props.modelValue);
      if (selectedIndex < props.options.length - 1) {
        emit('update:modelValue', props.options[selectedIndex + 1].value);
      } else {
        emit('update:modelValue', props.options[0].value);
      }
    }

    return {
      selectStyle,
      value,
      refs,
      afterMount,
      selectNext,
    };
  },
});
